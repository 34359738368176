<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('店铺管理')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh()" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" :style="{height: isQueryExpend?'auto':'55px'}">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('店铺名')" v-model="query.name" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('店铺ID')" v-model="query.shopId" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
  </el-row>

  <vxe-toolbar id="toolbar14" :custom="{storage: true}">
    <template v-slot:buttons>
      <el-button-group>
        <el-button type="primary" size="mini" @click="add()">
          <i class="el-icon-connection" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('绑定店铺')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="syncShop()">
          <i class="el-icon-refresh" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('同步店铺信息')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="syncToken()">
          <i class="el-icon-refresh" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('刷新token')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="syncOrder()">
          <i class="el-icon-refresh" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('同步店铺订单')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="updateShopOwner()">
          <i class="el-icon-refresh" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('分配子账号')}}</span>
          </transition>
        </el-button>
        <el-button type="danger" size="mini" @click="deleted()" v-if="hasPermission('shop:delete')">
          <i class="el-icon-delete" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('删除')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
    <template v-slot:tools>
      <el-button-group>
        <el-button type="success" @click="getShops(true)" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
        <el-button type="info" size="mini" @click="expendQuery()">
          <i class="el-icon-arrow-down" v-if="!isQueryExpend" style="display:inline-block;margin-right:4px; "></i>
          <i class="el-icon-arrow-up"  v-else style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('高级')}}</span>
          </transition>
        </el-button>
      </el-button-group>
     </template>
  </vxe-toolbar>
  <div class="table-wrap">
    <vxe-table style="width: 100%" :height="docHeight" ref="dataTable"
      border="inner" size="small"  stripe resizable  :data="shops" 
      show-overflow 
      highlight-hover-row highlight-current-row highlight-hover-column highlight-current-column
    >
      <vxe-table-column type="checkbox" width="40" :fixed="isMinScreen?null: 'left'"></vxe-table-column>
      <vxe-table-column field="id" :title="msg('ID')"  min-width="90" :fixed="isMinScreen ? null : 'left'" 
        sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
      ></vxe-table-column>
      <!-- <vxe-table-column field="photo" :title="msg('图片')" min-width="80" >
        <template v-slot="{ row }">
            <el-image  @click="openPdfPage(row.photo)"
              slot="reference" style="width: 40px;" :src="imgView+row.photo">
            </el-image>
        </template>
      </vxe-table-column> -->
      <vxe-table-column field="shopId" :title="msg('店铺ID')" min-width="120" 
        sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      ></vxe-table-column>
      <vxe-table-column field="name" :title="msg('店铺名')" min-width="120" 
        sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      >
        <template v-slot="{ row }">
          <span>{{row.name}}</span>
          <el-link :underline="false" type="primary" @click="updateShopName(row)" title="更新店铺名">
              <i class="el-icon-edit"></i>
          </el-link>
        </template>
      </vxe-table-column>
      <vxe-table-column field="createrName" :title="msg('归属人')" min-width="120" 
        sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      ></vxe-table-column>
     <!-- <vxe-table-column field="type" :title="msg('店铺类型')" min-width="135" 
        sortable :filters="dicts.expPlatforms"
      >
        <template v-slot="{ row }">
          <el-tag :type="dicts.cssTypes[row.type]" disable-transitions>
            {{dicts.expPlatforms[row.type]}}
          </el-tag>
        </template>
      </vxe-table-column> -->
      <!-- <vxe-table-column field="overview" :title="msg('简介')" min-width="200" :show-overflow="true"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
      </vxe-table-column> -->
      <vxe-table-column field="ownerName" :title="msg('子账号')" min-width="120" 
        sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      ></vxe-table-column>
      <vxe-table-column field="status" :title="msg('店铺状态')" min-width="200" :show-overflow="true"></vxe-table-column>
      <vxe-table-column field="created" :title="msg('绑定时间')" min-width="200" :show-overflow="true"></vxe-table-column>
      <vxe-table-column field="expireTime" :title="msg('授权过期时间')" min-width="200" :show-overflow="true"></vxe-table-column>
      <vxe-table-column field="accessToken" :title="msg('access_token')" min-width="200" :show-overflow="true"></vxe-table-column>
      <vxe-table-column field="refreshToken" :title="msg('refresh_token')" min-width="200" :show-overflow="true"></vxe-table-column>
      <vxe-table-column field="expireIn" :title="msg('expire_in')" min-width="200" :show-overflow="true"></vxe-table-column>
      <vxe-table-column field="syncRes" :title="msg('店铺同步结果')" min-width="200" :show-overflow="true"></vxe-table-column>
    </vxe-table>
    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page.sync="currentPage"
      :page-sizes="[10,20 ,50, 100, 200, 500, 1000,2000, 5000,10000,20000,50000]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
  
  <el-image-viewer v-if="showViewer" :on-close="()=>{this.showViewer = false}" :url-list="[imgUrl]" />
  <el-dialog :title="msg('同步订单')" :visible.sync="syncOrderVisiable" :fullscreen="isMinScreen"  width="450px" >
    <order-sync :shops="selectShops" ref="syncOrderRef" @closeDg="closeSyncOrder" />
  </el-dialog> 

  <el-dialog title="分配子账号" :close-on-click-modal="false" @opened="openUpdateDg"
      :visible.sync="dgUpdateVisible" width="600px"
      :fullscreen="isMinScreen">
          <shop-update :currId="currId" ref="updateRef" @close-dg="closeUpdateDialog" />
  </el-dialog>

</div>
</template> 

<script>
// @ is an alias to /src
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import OrderSync from './ShopOrderSync.vue';
import ShopUpdate from './ShopUpdate.vue';

export default {
  name: 'Shop',
  components: {
    ElImageViewer: ElImageViewer,
    OrderSync: OrderSync,
    ShopUpdate:ShopUpdate
  },
  mounted:function(){
    var _this = this;
    this.getShops();
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10,
          name: "",
          shopId:null,
        },
        currentPage: 1,   //分页的当前页
        shops: [],   //表格数据列表
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,
        selectShops: null,   //选中的列表

        imgView: this.$kit.api.imgView,  //图片显示
        showViewer:false,

        syncOrderVisiable: false,
        dgUpdateVisible:false,
        currId:null,
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    }
  },
  methods: {
    getShops: function(start){ //获取列表
      var _this = this;

      var param = this.$kit.rebuildQuery(this.query);
      if(start){
        param.start = 0;
        this.currentPage = 1;
      }

      this.axios.post(this.$kit.api.shopList, param).then(function (result) {
        if(result.status){
          
          _this.total = result.data.recordsTotal;
          var shopDatas = result.data.data;
          _this.shops = shopDatas;

        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.getShops();
    },
    expendQuery: function(){  //展开与收缩查询框
        this.isQueryExpend = !this.isQueryExpend;
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      //console.log(`每页 ${val} 条`);
      this.query.length = val;
      this.query.start = 0;
      this.getShops();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      //console.log(`当前页: ${val}`);
      this.query.start = this.query.length * (val -1);
      this.getShops();
    },
    add(){  //添加
      window.location.href=this.$kit.api.shopAdd;
    },
    syncShop(){
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      if(selectes.length>1){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      var id = selectes[0].shopId;

      this.axios.get(this.$kit.api.shopSync+"?shopId="+id).then((result) => {
        this.$message(result.msg);
        if(result.status){
          this.getShops();
        }
      }).catch(function (error) {
        console.error(error);
      });

    },
    syncToken(){
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      if(selectes.length>1){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      var id = selectes[0].shopId;

      this.axios.get(this.$kit.api.syncToken+"?shopId="+id).then((result) => {
        this.$message(result.msg);
        if(result.status){
          this.getShops();
        }
      }).catch(function (error) {
        console.error(error);
      });
    },
    syncOrder(){
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes){
        this.selectShops = selectes;
      }

      this.syncOrderVisiable = true;

    },
    closeSyncOrder(type){
      this.syncOrderVisiable = false;
      if(type){
        this.getShops();
      }
    },
    deleted(){  //删除
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      if(selectes.length>1){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      var id = selectes[0].id;
      
      var _this = this;

      this.$confirm(this.msg('确定要删除该店铺吗?'), this.msg('提示'), {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.$axios.get(_this.$kit.api.shopDel+"?id="+id)
            .then(function(result){
              _this.$message(result.msg || _this.msg('删除成功'));
              _this.getShops();
          });
        });
    },
    updateShopName(row){
      var _this = this;
      this.$prompt('请输入店铺名', this.msg('提示'), {
            closeOnClickModal:false,
            confirmButtonText: this.msg('确定'),
            cancelButtonText: this.msg('取消'),
        }).then(({ value }) => {
            this.$axios.post(this.$kit.api.updateShopName
                +"?id="+row.id
                +"&name="+(value?value:"")
            ).then((result) => {
                if(result.status){
                    this.getShops();
                }
                this.$message(result.msg);
            });
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '取消输入'
            });
        });
    },
    updateShopOwner(){
      //分配子账号
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      if(selectes.length>1){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      this.currId = selectes[0].id;
      this.dgUpdateVisible = true;
    },
    openUpdateDg(){
        this.$nextTick(() => {
            this.$refs.updateRef.init();
        });
    },
    closeUpdateDialog(type){  //编辑弹窗关闭后的回调事件
        if(type){
            this.getShops();
        }
        this.dgUpdateVisible = false;
    },
  }
}
</script>

<style class="scss" scoped>


</style>
