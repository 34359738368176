<!--店铺分配子账号-->
<template>
    <el-form ref="form" label-width="100px" size="mini">
        <el-row>
            <el-col :xs="20" :sm="20">
                <el-form-item :label="msg('选择子账号')">
                    <el-select v-model="owner" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
            <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
        </div>
    </el-form>
</template>

<script>
export default {
    name: 'shopUpdate',
    data(){
        return {
            owner:null,
            options:[],
        }
    },
    computed:{
    },
    props:{
        currId: Number
    },
    methods:{
        init(){
            this.owner = null;
            this.options = [];
            this.getUsers();
        },
        getUsers(){
            var _this =this;
            this.$axios.get(this.$kit.api.getChildList)
            .then(function(result){
                if(result){
                    let data = result.data;
                    if(data){
                        data.forEach(e => {
                            _this.options.push({
                                label:e.fullname,
                                value:e.id,
                            });
                        });
                    }
                }
            });
        },
        onSubmit(){
            if(!this.owner){
                this.$message(this.msg("请选择子账号"));
                return;
            }

            var _this = this;

            let param = {
                id:this.currId,
                owner:this.owner
            }
            this.$axios.post(this.$kit.api.shopUpdateOwner,param)
            .then(function(result){
                if(result.status){
                    _this.$message(result.msg || _this.msg('更新成功'));
                    if(result.status){
                        _this.$emit("close-dg", true);
                    }
                }else{
                    _this.$message(result.msg || _this.msg('更新失败'));
                }
            });

        },
    }
}
</script>
<style lang="scss">
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
    .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
</style>