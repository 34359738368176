<template>
<el-form ref="form" :model="form" label-width="80px">
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('已选店铺')">
                <span v-if="!shops || shops.length == 0 ">所有</span>
                <template v-else>
                    <span v-for="shop in shops" :key="shop.id">{{shop.shopId+(shop.name?("("+shop.name+")"):"")}}</span>
                </template>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('开始时间')" label-width="100px">
                <el-date-picker v-model="st" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
        </el-col>
    </el-row>
  
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'ShopOrderSync',
  data(){
    return{
       form: {},
       st: null
    }
    
  }, 
  computed:{
  },
  props:{
      shops:null,
  },
  mounted: function(){
       
  },
  methods:{
    onSubmit(){
        var p = null;
        var hasP = false;
        if(this.shops && this.shops.length>0){
            p = "?ids="+this.shops.map(e => e.id);
            hasP = true;
        }
        if(this.st){
            if(hasP){
                p+="&st="+this.st;
            }else{
                p="?st="+this.st;
            }
        }

        var url = this.$kit.api.shopSyncOrder+p;
       
        var _this = this;
        this.$axios.get(url)
            .then(function(result){
                if(result.status){
                    if(result.status){
                        _this.$emit("closeDg", true);
                    }
                    _this.$message(result.msg ||  _this.msg('同步订单成功'));
                }else{
                    _this.$message(result.msg || _this.msg('同步订单成功'));
                }
               
            });
    }
  }
}
</script>

<style class="scss" scoped>


</style>
